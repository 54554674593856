import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { useConfiguration } from '../../context/configurationContext';


//Static assets
import facebookImage from '../../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../../assets/biketribe-twitter-sharing-600x314.jpg';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { landingPageListings, sendNewsLetter } from './LandingPage.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';

import RenderDesktopLandingPage from './RenderDesktopLandingPage';
import RenderMobileLandingPage from './RenderMobileLandingPage';
import homepageSectionsJSON from '../../cache/homepageSections.json';

import css from './LandingPage.module.css';
import { getDefaultLocale, isArrayLength } from '../../util/genericHelpers';

const getSections = response => {
  const attributes = response?.attributes;
  if (!attributes) return [];

  // Assuming section keys are formatted as 'section1', 'section2', etc.
  const sectionKeys = Object.keys(attributes).filter(key => key.startsWith('section'));
  return sectionKeys.map(key => attributes[key] || {});
};

const homepageSections = getSections(homepageSectionsJSON[getDefaultLocale()]);

//Section pages
export const LandingPageComponent = props => {
  const { intl, scrollingDisabled, history, viewport, listings } = props;

  const config = useConfiguration();
  const siteTitle = config.marketplaceName;

  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;
  const isMobileLayout = typeof window !== 'undefined' && window.innerWidth < 768;
  // const isMobileLayout = viewport.width && viewport.width > 0 && viewport.width < 768;




  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        showMobileNavBar
        className={css.pageRoot}
        topbar={<TopbarContainer />}
        footer={<FooterContainer showMobileNavBar />}
      >
        {isMobileLayout ? (
          <RenderMobileLandingPage
            viewport={viewport}
            history={history}
            intl={intl}
            listings={listings}
            homepageSections={homepageSections}
            isMobileSection={true}
          />
        ) : (
          <RenderDesktopLandingPage
            viewport={viewport}
            history={history}
            listings={listings}
            homepageSections={homepageSections}
          />
        )}
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentPageResultIds } = state.LandingPage;
  const listings = getListingsById(state, currentPageResultIds);

  if (isArrayLength(listings)) {
    listings.sort((a, b) => {
      return landingPageListings.indexOf(a.id.uuid) - landingPageListings.indexOf(b.id.uuid);
    });
  }

  return {
    scrollingDisabled: isScrollingDisabled(state),
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendNewsLetter: params => dispatch(sendNewsLetter(params)),
});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
